var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card-actions',{ref:"loadingCard",attrs:{"action-collapse":"","title":"Configuração atual do Jogo de Atenção"}},[_c('validation-observer',{ref:"verificaCamposObrigatorios"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Quantidade de jogos para calcular desvio e média")]),_c('validation-provider',{attrs:{"rules":"required|between:1,100","name":"Quantidade de jogos para calcular desvio e média"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Quantidade de jogos para calcular desvio e média","disabled":""},model:{value:(_vm.consultarGame.quantidadeDeTestes),callback:function ($$v) {_vm.$set(_vm.consultarGame, "quantidadeDeTestes", $$v)},expression:"consultarGame.quantidadeDeTestes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"consultaduracaoDoTeste"}},[_vm._v("Duração do jogo em minutos e segundos")]),_c('validation-provider',{attrs:{"rules":"required","name":"Duração do jogo em minutos e segundos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{class:errors.length > 0
                          ? 'form-control is-invalid'
                          : 'form-control',attrs:{"type":"time","options":_vm.options.consultaduracaoDoTeste,"state":errors.length > 0 ? false : null,"placeholder":"00:00","disabled":""},model:{value:(_vm.consultaduracaoDoTeste),callback:function ($$v) {_vm.consultaduracaoDoTeste=$$v},expression:"consultaduracaoDoTeste"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.desenhaMatriz)?_c('b-col',{staticClass:"mx-auto mt-2",attrs:{"md":"12"}},[_c('div',{key:'Referencia-' + String(Math.floor(Math.random() * 10)),staticClass:"containerReferencia"},[_c('div',{attrs:{"id":"box"}},_vm._l((_vm.imagens),function(coluna,index){return _c('div',{key:'referencia-' + String(index + 1),staticClass:"imagemReferencia",attrs:{"id":'referencia-' + String(index + 1)},domProps:{"innerHTML":_vm._s(_vm.imagemSvg(coluna.codigo))}})}),0)])]):_vm._e()],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card-actions',{ref:"loadingCard",attrs:{"no-actions":"","title":"Inserção de Jogo de Atenção"}},[_c('b-card-text',[_vm._v(" Nesta tela você deverá realizar o upload das imagens svg e definir as configurações que serão utilizadas no Jogo de Atenção. ")]),_c('validation-observer',{ref:"verificaCamposObrigatorios"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Imagem1"}},[_vm._v("Insira aqui a imagem 1")]),_c('validation-provider',{attrs:{"rules":"required|imageIsSvg","name":"Imagem 1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
                          var validate = ref.validate;
return [_c('b-form-file',{ref:"Imagem1",class:errors.length > 0
                          ? 'form-control is-invalid'
                          : 'form-control',attrs:{"id":"Imagem1","placeholder":"Arraste uma imagem SVG ou clique em Buscar","drop-placeholder":"Solte o arquivo aqui...","browse-text":"Buscar","accept":".svg"},on:{"input":function($event){return validate('Imagem1')}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Imagem2"}},[_vm._v("Insira aqui a imagem 2")]),_c('validation-provider',{attrs:{"rules":"required|imageIsSvg","name":"Imagem 2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
                          var validate = ref.validate;
return [_c('b-form-file',{ref:"Imagem2",class:errors.length > 0
                          ? 'form-control is-invalid'
                          : 'form-control',attrs:{"id":"Imagem2","placeholder":"Arraste uma imagem SVG ou clique em Buscar","drop-placeholder":"Solte o arquivo aqui...","browse-text":"Buscar","accept":".svg"},on:{"input":function($event){return validate('Imagem2')}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Imagem3"}},[_vm._v("Insira aqui a imagem 3")]),_c('validation-provider',{attrs:{"rules":"required|imageIsSvg","name":"Imagem 3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
                          var validate = ref.validate;
return [_c('b-form-file',{ref:"Imagem3",class:errors.length > 0
                          ? 'form-control is-invalid'
                          : 'form-control',attrs:{"id":"Imagem3","placeholder":"Arraste uma imagem SVG ou clique em Buscar","drop-placeholder":"Solte o arquivo aqui...","browse-text":"Buscar","accept":".svg"},on:{"input":function($event){return validate('Imagem3')}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Imagem4"}},[_vm._v("Insira aqui a imagem 4")]),_c('validation-provider',{attrs:{"rules":"required|imageIsSvg","name":"Imagem 4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
                          var validate = ref.validate;
return [_c('b-form-file',{ref:"Imagem4",class:errors.length > 0
                          ? 'form-control is-invalid'
                          : 'form-control',attrs:{"id":"Imagem4","placeholder":"Arraste uma imagem SVG ou clique em Buscar","drop-placeholder":"Solte o arquivo aqui...","browse-text":"Buscar","accept":".svg"},on:{"input":function($event){return validate('Imagem4')}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Imagem5"}},[_vm._v("Insira aqui a imagem 5")]),_c('validation-provider',{attrs:{"rules":"required|imageIsSvg","name":"Imagem 5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
                          var validate = ref.validate;
return [_c('b-form-file',{ref:"Imagem5",class:errors.length > 0
                          ? 'form-control is-invalid'
                          : 'form-control',attrs:{"id":"Imagem5","placeholder":"Arraste uma imagem SVG ou clique em Buscar","drop-placeholder":"Solte o arquivo aqui...","browse-text":"Buscar","accept":".svg"},on:{"input":function($event){return validate('Imagem5')}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Quantidade de jogos para calcular desvio e média")]),_c('validation-provider',{attrs:{"rules":"required|between:1,100","name":"Quantidade de jogos para calcular desvio e média"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.QuantidadeDeTestes),callback:function ($$v) {_vm.QuantidadeDeTestes=$$v},expression:"QuantidadeDeTestes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"duracaoDoTeste"}},[_vm._v("Duração do jogo em minutos e segundos (máximo 23 min 59 seg)")]),_c('validation-provider',{attrs:{"rules":"required","name":"Duração do jogo em minutos e segundos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('cleave',{class:errors.length > 0
                          ? 'form-control is-invalid'
                          : 'form-control',attrs:{"type":"time","options":_vm.options.duracaoDoTeste,"state":errors.length > 0 ? false : null,"placeholder":"00:00"},model:{value:(_vm.duracaoDoTeste),callback:function ($$v) {_vm.duracaoDoTeste=$$v},expression:"duracaoDoTeste"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"btEspaco float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Enviar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.limpaCampos()}}},[_vm._v(" Limpar ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }