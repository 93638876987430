<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card-actions
          ref="loadingCard"
          action-collapse
          title="Configuração atual do Jogo de Atenção"
        >
          <validation-observer ref="verificaCamposObrigatorios">
            <b-form>
              <b-row>
                <!-- Quantidade de testes para calcular desvio e média -->
                <b-col md="6">
                  <b-form-group>
                    <label>Quantidade de jogos para calcular desvio e média</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required|between:1,100"
                      name="Quantidade de jogos para calcular desvio e média"
                    >
                      <b-form-input
                        v-model="consultarGame.quantidadeDeTestes"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Quantidade de jogos para calcular desvio e média"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Duração do teste em minutos e segundos -->
                <b-col md="6">
                  <b-form-group>
                    <label
                      for="consultaduracaoDoTeste"
                    >Duração do jogo em minutos e segundos</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Duração do jogo em minutos e segundos"
                    >
                      <cleave
                        v-model="consultaduracaoDoTeste"
                        type="time"
                        :class="
                          errors.length > 0
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                        :options="options.consultaduracaoDoTeste"
                        :state="errors.length > 0 ? false : null"
                        placeholder="00:00"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- exibindo as imagens svg -->
                <b-col
                  v-if="desenhaMatriz"
                  md="12"
                  class="mx-auto mt-2"
                >
                  <div
                    :key="
                      'Referencia-' + String(Math.floor(Math.random() * 10))
                    "
                    class="containerReferencia"
                  >
                    <div id="box">
                      <div
                        v-for="(coluna, index) in imagens"
                        :id="'referencia-' + String(index + 1)"
                        :key="'referencia-' + String(index + 1)"
                        class="imagemReferencia"
                        v-html="imagemSvg(coluna.codigo)"
                      />
                    </div>
                  </div>
                </b-col>

                <!-- Fecha linha, form e validation observer  -->
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-actions>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-card-actions
          ref="loadingCard"
          no-actions
          title="Inserção de Jogo de Atenção"
        >
          <b-card-text>
            Nesta tela você deverá realizar o upload das imagens svg e definir
            as configurações que serão utilizadas no Jogo de Atenção.
          </b-card-text>

          <validation-observer ref="verificaCamposObrigatorios">
            <b-form>
              <b-row>
                <!-- Imagem 1 -->
                <b-col md="6">
                  <b-form-group>
                    <label for="Imagem1">Insira aqui a imagem 1</label>
                    <validation-provider
                      #default="{ errors, validate }"
                      rules="required|imageIsSvg"
                      name="Imagem 1"
                    >
                      <b-form-file
                        id="Imagem1"
                        ref="Imagem1"
                        :class="
                          errors.length > 0
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                        placeholder="Arraste uma imagem SVG ou clique em Buscar"
                        drop-placeholder="Solte o arquivo aqui..."
                        browse-text="Buscar"
                        accept=".svg"
                        @input="validate('Imagem1')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Imagem 2 -->
                <b-col md="6">
                  <b-form-group>
                    <label for="Imagem2">Insira aqui a imagem 2</label>
                    <validation-provider
                      #default="{ errors, validate }"
                      rules="required|imageIsSvg"
                      name="Imagem 2"
                    >
                      <b-form-file
                        id="Imagem2"
                        ref="Imagem2"
                        :class="
                          errors.length > 0
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                        placeholder="Arraste uma imagem SVG ou clique em Buscar"
                        drop-placeholder="Solte o arquivo aqui..."
                        browse-text="Buscar"
                        accept=".svg"
                        @input="validate('Imagem2')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Imagem 3 -->
                <b-col md="6">
                  <b-form-group>
                    <label for="Imagem3">Insira aqui a imagem 3</label>
                    <validation-provider
                      #default="{ errors, validate }"
                      rules="required|imageIsSvg"
                      name="Imagem 3"
                    >
                      <b-form-file
                        id="Imagem3"
                        ref="Imagem3"
                        :class="
                          errors.length > 0
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                        placeholder="Arraste uma imagem SVG ou clique em Buscar"
                        drop-placeholder="Solte o arquivo aqui..."
                        browse-text="Buscar"
                        accept=".svg"
                        @input="validate('Imagem3')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Imagem 4 -->
                <b-col md="6">
                  <b-form-group>
                    <label for="Imagem4">Insira aqui a imagem 4</label>
                    <validation-provider
                      #default="{ errors, validate }"
                      rules="required|imageIsSvg"
                      name="Imagem 4"
                    >
                      <b-form-file
                        id="Imagem4"
                        ref="Imagem4"
                        :class="
                          errors.length > 0
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                        placeholder="Arraste uma imagem SVG ou clique em Buscar"
                        drop-placeholder="Solte o arquivo aqui..."
                        browse-text="Buscar"
                        accept=".svg"
                        @input="validate('Imagem4')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Imagem 5 -->
                <b-col md="6">
                  <b-form-group>
                    <label for="Imagem5">Insira aqui a imagem 5</label>
                    <validation-provider
                      #default="{ errors, validate }"
                      rules="required|imageIsSvg"
                      name="Imagem 5"
                    >
                      <b-form-file
                        id="Imagem5"
                        ref="Imagem5"
                        :class="
                          errors.length > 0
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                        placeholder="Arraste uma imagem SVG ou clique em Buscar"
                        drop-placeholder="Solte o arquivo aqui..."
                        browse-text="Buscar"
                        accept=".svg"
                        @input="validate('Imagem5')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Parâmetro de desvio -->
                <!-- <b-col md="6">
                  <b-form-group>
                    <label>Parâmetro de desvio (utilize dois dígitos)</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required|between:1,100"
                      name="Parâmetro de desvio (utilize dois dígitos)"
                    >
                      <cleave
                        v-model="ParametroDeDesvio"
                        :class="
                          errors.length > 0
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                        :options="options.ParametroDeDesvio"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Parâmetro de desvio (utilize dois dígitos)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->

                <!-- Quantidade de testes para calcular desvio e média -->
                <b-col md="6">
                  <b-form-group>
                    <label>Quantidade de jogos para calcular desvio e média</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required|between:1,100"
                      name="Quantidade de jogos para calcular desvio e média"
                    >
                      <b-form-input
                        v-model="QuantidadeDeTestes"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Duração do teste em minutos e segundos -->
                <b-col md="6">
                  <b-form-group>
                    <label
                      for="duracaoDoTeste"
                    >Duração do jogo em minutos e segundos (máximo 23 min 59
                      seg)</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Duração do jogo em minutos e segundos"
                    >
                      <cleave
                        v-model="duracaoDoTeste"
                        type="time"
                        :class="
                          errors.length > 0
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                        :options="options.duracaoDoTeste"
                        :state="errors.length > 0 ? false : null"
                        placeholder="00:00"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Botão enviar -->
                <b-col cols="12">
                  <b-button
                    class="btEspaco float-right"
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Enviar
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    @click="limpaCampos()"
                  >
                    Limpar
                  </b-button>
                </b-col>

                <!-- Fecha linha, form e validation observer  -->
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate"
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BFormFile,
  BBadge,
  BFormInput,
  BFormGroup,
  BForm,
  BButton,
  BFormTimepicker
  // BLink
} from "bootstrap-vue"
import { required, length, validate } from "@validations"
import Cleave from "vue-cleave-component"
import useJwt from "@/auth/jwt/useJwt"
import BCardActions from "@/@core/components/b-card-actions/BCardActions.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { getHomeRouteForLoggedInUser } from "@/auth/utils"

// Permite apenas imagens em svg
extend("imageIsSvg", {
  name: "Permite apenas imagens em svg",
  validate: nome => {
    const conteudoCampo = document.getElementById(nome).value
    return (
      conteudoCampo.substring(conteudoCampo.length - 4).toLowerCase() === ".svg"
    )
  },
  message: "O campo {_field_} aceita apenas SVG."
})

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BCardText,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    Cleave,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  data() {
    return {
      // ParametroDeDesvio: null,
      desenhaMatriz: false,
      dados: null,
      imagens: [],
      // referencia: [],
      matriz: [],
      tempo: 0,

      referencia: [
        {
          codigo: 4
        },
        {
          codigo: 5
        }
      ],

      QuantidadeDeTestes: null,
      duracaoDoTeste: null,
      TempoMaximo: null,
      consultaduracaoDoTeste: null,
      consultarGame: {
        idDoGame: 0,
        parametroDeDesvio: 0,
        quantidadeDeTestes: 0,
        tempoMaximo: 0
      },
      Imagem1: null,
      Imagem2: null,
      Imagem3: null,
      Imagem4: null,
      Imagem5: null,
      consultarGameAtencao: {
        idDoGame: 0,
        parametroDeDesvio: 0,
        quantidadeDeTestes: 0,
        tempoMaximo: 0,
        imagem1: null,
        imagem2: null,
        imagem3: null,
        imagem4: null,
        imagem5: null
      },
      options: {
        duracaoDoTeste: {
          delimiters: [":"],
          blocks: [2, 2],
          numericOnly: true
        },
        consultaduracaoDoTeste: {
          delimiters: [":"],
          blocks: [2, 2],
          numericOnly: true
        }
        // ParametroDeDesvio: {
        //   delimiters: ["%"],
        //   blocks: [2, 0],
        //   numericOnly: true
        // }
      }
    }
  },
  // beforeMount() {
  //   this.buscarTesteDeConcentracao()
  // },

  mounted() {
    this.$refs["loadingCard"].showLoading = false
    this.getConsultarJogo()
  },
  methods: {
    async getConsultarJogo() {
      const resp = await useJwt
        .consultaJogoAtencao()
        .then(response => response.data)
        .catch(response => {
          const catchError = Object.assign({}, response)
          this.MensagemErrorCarregaConfiguracao("danger", "top-center")
        })
      if (resp.sucesso) {
        this.consultarGameAtencao = resp.dados
        console.log("JogoAtenção ", this.consultarGameAtencao)
        this.consultarGame.quantidadeDeTestes = this.consultarGameAtencao.quantidadeDeTestes
        this.consultarGame.tempoMaximo = this.consultarGameAtencao.tempoMaximo
        // this.Imagem1 = this.consultaJogoAtencao.imagem1
        // this.Imagem2 = this.consultaJogoAtencao.imagem2
        // this.Imagem3 = this.consultaJogoAtencao.imagem3
        // this.Imagem4 = this.consultaJogoAtencao.imagem4
        // this.Imagem5 = this.consultaJogoAtencao.imagem5
        this.prepararTesteDeConcentracao()

        this.converteTempoExibicao(2)
      }
    },

    prepararTesteDeConcentracao() {
      this.$refs["loadingCard"].showLoading = false
      this.imagens = this.consultarGameAtencao.imagens
      // Remove width e height do primeiro nó de cada svg a fim de manter a imagem sem altura e largura
      let regex = new RegExp(/(width="|height=").+?" /g)
      for (let i = 0; i < this.imagens.length; i++) {
        let svgSeparado = this.imagens[i].svg.split(">")
        svgSeparado[0] = svgSeparado[0].replace(regex, "", 1)
        let svgJuntado = svgSeparado.join(">")
        this.imagens[i].svg = svgJuntado
      }
      this.desenhaMatriz = true

      // console.log("Imagem 1: ", this.imagens[0].svg)
    },

    imagemSvg(codigo) {
      // console.log("Coluna: ", coluna)
      // console.log("Código: ", codigo)
      // console.log("imagens: ", this.imagens)
      const imagem = this.imagens.find(y => y.codigo === codigo)
      // console.log("imagem: ", imagem)
      // console.log("Imagem svg", imagem.svg)
      return imagem.svg
    },

    async salvarConfigConcentracao() {
      // Colocar um loading aqui
      this.converteDuracao(1)
      let formData
      const prepara = await this.preparaImagens().then(() => {
        formData = new FormData()
        formData.append("Imagem1", this.Imagem1)
        formData.append("Imagem2", this.Imagem2)
        formData.append("Imagem3", this.Imagem3)
        formData.append("Imagem4", this.Imagem4)
        formData.append("Imagem5", this.Imagem5)
        // formData.append("ParametroDeDesvio", this.ParametroDeDesvio)
        formData.append("QuantidadeDeTestes", this.QuantidadeDeTestes)
        formData.append("TempoMaximo", this.TempoMaximo)
        return true
      })

      // console.log("formData: ", formData)
      // console.log("prepara: ", prepara)

      if (prepara) {
        // console.log("Entrou em salvar")
        const resp = await useJwt
          .salvarConfigConcentracao(formData)
          .then(response => response.data)
          .catch(response => {
            const erro = response
            // console.log("Erro: ", erro)
            this.MensagemErrorSalvarConfiguracaoConcentracao(
              "danger",
              "top-center"
            )
          })

        if (resp.sucesso) {
          setTimeout(() => {
            // this.$router.push({
            //   name: getHomeRouteForLoggedInUser(
            //     this.$store.state.user.idDoPerfil
            //   )
            // })
          }, 2000)
          this.MensagemSalvarConfiguracaoConcentracao("success", "top-center")
        }
        this.$refs["loadingCard"].showLoading = false
        setTimeout(() => {
          this.$router.go()
        }, 2000)
      }
    },
    async preparaImagens() {
      this.Imagem1 = await this.$refs.Imagem1.files[0]
      this.Imagem2 = await this.$refs.Imagem2.files[0]
      this.Imagem3 = await this.$refs.Imagem3.files[0]
      this.Imagem4 = await this.$refs.Imagem4.files[0]
      this.Imagem5 = await this.$refs.Imagem5.files[0]
    },
    converteTempoExibicao(tipo) {
      if (tipo === 2) {
        const minutos = Math.floor(this.consultarGame.tempoMaximo / 60)
        const segundos = this.consultarGame.tempoMaximo % 60
        if (minutos > 9) {
          this.consultaduracaoDoTeste = String(minutos)
        } else {
          this.consultaduracaoDoTeste = "0" + String(minutos)
        }

        if (segundos > 9) {
          this.consultaduracaoDoTeste =
            this.consultaduracaoDoTeste + String(segundos)
        } else {
          this.consultaduracaoDoTeste =
            this.consultaduracaoDoTeste + "0" + String(segundos)
        }
      }
    },
    converteDuracao(tipo) {
      // Passar 1 ou 2 no parâmetro 'tipo'
      if (tipo === 1) {
        // Minutos para segundos:
        this.TempoMaximo =
          Number(this.duracaoDoTeste.slice(0, 2)) * 60 +
          Number(this.duracaoDoTeste.slice(2, 4))
        // console.log("TempoMaximo Segundos: ", this.TempoMaximo)
      } else {
        // Segundos para minutos
        const minutos = Math.floor(this.TempoMaximo / 60)
        const segundos = this.TempoMaximo % 60
        if (minutos > 9) {
          this.duracaoDoTeste = String(minutos)
        } else {
          this.duracaoDoTeste = "0" + String(minutos)
        }

        if (segundos > 9) {
          this.duracaoDoTeste = this.duracaoDoTeste + String(segundos)
        } else {
          this.duracaoDoTeste = this.duracaoDoTeste + "0" + String(segundos)
        }
      }
    },
    validationForm() {
      // console.log(this.duracaoDoTeste)
      this.$refs.verificaCamposObrigatorios.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.$refs["loadingCard"].showLoading = true
          this.salvarConfigConcentracao()
        }
      })
    },
    MensagemErrorCarregaConfiguracao(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Erro ao carregar os dados da ultima configuração!",
            icon: "ErrorIcon",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemErrorSalvarConfiguracaoConcentracao(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title:
              "Erro ao salvar jogo de atenção. Por favor, tente novamente.",
            icon: "ErrorIcon",
            text:
              "Por favor, verifique se os dados enviados estão corretos ou entre em contato com o administrador.",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemSalvarConfiguracaoConcentracao(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Jogo salvo com sucesso.",
            icon: "ThumbsUpIcon",
            variant,
            text:
              "Os usuários que iniciarem uma nova sessão de jogos irão receber a nova configuração definida."
          }
        },
        {
          position
        }
      )
    },

    limpaCampos() {
      ;(this.$refs.Imagem1.files = []),
        (this.$refs.Imagem2.files = []),
        (this.$refs.Imagem3.files = []),
        (this.$refs.Imagem4.files = []),
        (this.$refs.Imagem5.files = []),
        (this.QuantidadeDeTestes = null),
        (this.duracaoDoTeste = null)
    }
  }
}
</script>
<style scoped>
.containerMain {
  background-color: white;
  padding: 0.1%;
  border-radius: 15px;
}
.tabela {
  width: 100%;
  display: table;
}
.linha {
  display: table-row;
}
.containerReferencia {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imagemReferencia {
  padding: 5%;
  width: 50%;
}

#box {
  border-radius: 15px;
  width: 30%;
  border: 1px gray solid;
  box-shadow: 0px 5px 10px #969696;
  -webkit-box-shadow: 0px 5px 10px #969696;
  display: flex;
  flex-direction: row;
}

svg {
  overflow: hidden;
  vertical-align: middle;
  width: 20px;
}

.btEspaco {
  margin-left: 1%;
}
</style>
